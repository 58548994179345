<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': !_getBrigades.length || !$store.state.userObject }"
	>
		<v-preloader
			v-if="loading || !_getBrigades || !$store.state.userObject"
			:message="errorMessage"
		></v-preloader>

		<div class="container" v-if="!loading && _getBrigades && $store.state.userObject">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['Brigade']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack :title="$t('sidebar.brigade')" />
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
										</div>
										<div class="table__filter--right">
											<router-link
												v-if="$store.getters.userPermission(['change_reporting-1rh'])"
												:to="'/' + $i18n.locale + '/account/brigade/create'"
												class="btn--link btn--link--plus"
											>
												{{ $t('button.add') }}
												<img src="../../../assets/img/icon-plus.svg" />
											</router-link>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										:server-items-length="filteredlist.length"
									>
										<template v-slot:item.title="{ item }">
											<a class="tb__table--link">
												<span v-html="item.title"></span>
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<router-link :to="'/' + $i18n.locale + '/account/brigade/' + `${item.id}`">
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
											<router-link
												:to="'/' + $i18n.locale + '/account/brigade/' + `${item.id}/edit`"
												style="margin-left: 15px"
											>
												<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 48 48" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#52A5FC"><path d="M37 4.5c-1.2 1.4-.9 2.1 1.8 4.7 2.6 2.7 3.3 3 4.7 1.8 1-.8 1.5-2.5 1.3-4.4-.3-2.7-.7-3.1-3.4-3.4-1.9-.2-3.6.3-4.4 1.3zM4.4 9.1c-1.7 1.9-1.9 3.8-1.9 17 0 20.1-.7 19.4 19.5 19.4 19.2 0 19.3 0 19.8-14.2.2-5.8-.1-10.4-.8-11.3-1.9-2.8-3 1.1-3 11.3 0 9.5-.1 9.8-2.6 10.7-3.3 1.3-23.5 1.3-26.9 0l-2.6-1 .3-15.2.3-15.3 9-.2C25.7 10 29 9.4 29 8c0-.6-4.6-1-11.3-1C7.4 7 6.1 7.2 4.4 9.1z"/><path d="M25.8 15.8c-6 6.2-9.2 12.2-7.4 13.9 1.7 1.7 8.6-2.1 14.6-8.1l6.4-6.6-2.9-3c-1.6-1.6-3.2-3-3.5-3-.4 0-3.6 3-7.2 6.8z"/></g></svg>
											</router-link>
											<button @click="$modal.show('modal_remove_brigade')" class="item__remove">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="18" height="18" style="margin-left:15px" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.56 7.34c0 .83.67 1.5 1.5 1.5h.8v17.72c0 2.73 2.21 4.94 4.93 4.94h12.42c2.72 0 4.93-2.21 4.93-4.94V8.84h.8c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-5.25V4.29C22.69 2.2 20.99.5 18.9.5h-5.8c-2.09 0-3.79 1.7-3.79 3.79v1.55H4.06c-.83 0-1.5.67-1.5 1.5zm18.67 5.76v11.14c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V13.1c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5zm-8.92-8.81c0-.44.35-.79.79-.79h5.8c.44 0 .79.35.79.79v1.55h-7.38V4.29zm-1.54 8.81c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v11.14c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V13.1z" fill="red"/></svg>
											</button>

											<modal
												class="modal__block modal__block--middle modal__block--success"
												name="modal_remove_brigade"
												:width="550"
												:min-width="550"
												height="auto"
											>
												<div class="modal__block--wrapper">
													<div
														class="modal__block--close"
														@click="$modal.hide('modal_remove_brigade')"
													>
														<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</div>
													<div class="modal__wrapper">
														<div class="modal__header">
															<div class="modal__title">
																{{ $t('messages.are_you_sure_to_remove_brigade', [item.title])
																}}<br />
															</div>
														</div>

														<div class="modal__content">
															<div class="modal__form">
																<div class="block__row">
																	<div class="block__row--left">
																		<a
																			class="block__item block__item--green"
																			@click="removeBrigade(item.id)"
																		>
																			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																			{{ $t('button.delete') }}
																		</a>
																	</div>
																	<div class="block__row--right">
																		<a
																			class="block__item block__item--blue"
																			@click="$modal.hide('modal_success')"
																		>
																			{{ $t('button.cancel') }}
																		</a>
																	</div>
																</div>
																<div
																	class="form__block--line form__messages"
																	v-if="message.status"
																	:class="{
																		'form__messages--error': message.status != 200,
																		'form__messages--success': message.status == 200,
																	}"
																>
																	<label class="form__block--input">
																		<span class="form__block--title">
																			{{ message.text }}
																		</span>
																	</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</modal>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ReportManageErrorModal :message="message" :errorMessage="errorMessage" />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { apiSecond } from '@/boot/axios'

import HeaderBack from '@/components/UI/HeaderBack.vue'
import ReportManageErrorModal from '@/components/reporting-management/ReportManageErrorModal.vue'

export default {
	components: {
		HeaderBack,
		ReportManageErrorModal,
	},
	data() {
		return {
			loading: false,
			headers: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.brigade_name'), value: 'title', width: '40vw' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '50px',
				},
			],
			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},
	computed: {
		...mapGetters(['_getBrigades']),
		filteredlist() {
			return this._getBrigades.filter((brigade) => {
				return brigade.title.toLowerCase().startsWith(this.filters.search.toLowerCase())
			})
		},
	},
	mounted() {
		this._fetchBrigades()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.references'),
			}
		},
		meta: [],
	},
	methods: {
		...mapActions(['_fetchBrigades']),
		removeBrigade(id) {
			this.loading = true
			apiSecond
				.delete(`/v2/brigade/destroy/${id}`)
				.then(() => {
					this._fetchBrigades()
						.catch((error) => {
							this.checkError(error)
						})
						.finally(() => {
							this.loading = false
						})
				})
				.catch((error) => {
					this.loading = false
					this.checkError(error)
				})
				.finally(() => {
					document.querySelector('.preloader').classList.remove('active')
				})
		},
		checkError(error) {
			if (error?.response?.status > 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			} else if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error?.response?.data?.message
				this.$router.push('/' + this.$i18n.locale + '/login')
			} else {
				this.errorMessage.status = 422
				this.errorMessage.text = error?.response?.data?.message
			}
			this.$modal.show('ReportManageErrorModal')
		},
	},
}
</script>

<style scoped>
.item__remove {
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
}
</style>
